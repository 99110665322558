<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center page-not-found"
  >
    <h1>
      <b class="mx-2">404</b> |
      <small class="mx-2">{{ $t('message.page-not-found') }}</small>
      <br />
    </h1>
    <div class="d-flex align-items-center mt-4">
      <a
        href="https://www.askqotd.com"
        rel="noopener"
        title="go to home"
        class="font-weight-bold mx-2"
      >
        Go to Home
      </a>
      <img :src="arrowImg" width="14" height="14" alt="" class="mt-1" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  computed: {
    arrowImg() {
      return require('@/assets/images/transit_enterexit_black_24dp.svg');
    },
  },
  metaInfo() {
    return {
      title: this.$t('app.title'),
      titleTemplate: '%s | ' + this.$t('message.page-not-found'),
      htmlAttrs: {
        lang: this.$root.$i18n.locale,
        amp: true,
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.page-not-found {
  width: 100%;
  height: 100%;
  background: white;
}
a {
  text-decoration: none;
  color: #3e59f2 !important;
}
</style>
